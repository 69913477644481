import * as React from "react";
import {
  ProductVariant,
  Product,
} from "../../pages/BuyProduct/Screen/Products/types";

export interface CartItem {
  product: ProductVariant | Product;
  quantity: number;
  type: "Variant" | "Product";
  useEvoucher: boolean;
  ordertype: string;
}

interface Props {
  cart: CartItem[];
  addToCart: (
    data: ProductVariant | Product,
    quantity: number,
    type: "Variant" | "Product",
    useEvoucher: boolean,
    ordertype: string | null
  ) => boolean;
  removeFromCart: (id: string) => boolean;
  clearCart: () => boolean;
  setUseEvoucher: (
    data: ProductVariant | Product,
    useEvoucher: boolean
  ) => boolean | undefined;
}

export const CartContext = React.createContext({} as Props);

const CartContextContainer: React.FC = ({ children }) => {
  const [cart, setCart] = React.useState<CartItem[]>([]);

  //check if we have data in local storage
  React.useEffect(() => {
    let cartData = localStorage.getItem("@empomart-cart");
    if (!cartData) setCart([]);
    else setCart(JSON.parse(cartData));
  }, []);

  const cartContextController = {
    setUseEvoucher: (data: ProductVariant | Product, useEvoucher: boolean) => {
      let isIn = cart.findIndex((c) => c?.product?._id === data?._id);
      if (isIn !== -1) {
        let newCart = [...cart];
        newCart[isIn] = {
          product: data,
          quantity: cart[isIn]?.quantity,
          useEvoucher: useEvoucher,
          type: cart[isIn]?.type,
          ordertype: cart[isIn]?.ordertype,
        };

        //set to cart
        setCart(newCart);
        //save to local storage
        localStorage.setItem("@empomart-cart", JSON.stringify(newCart));
        return true;
      }
    },
    addToCart: (
      data: ProductVariant | Product,
      quantity: number,
      type: "Variant" | "Product",
      useEvoucher: boolean = false,
      ordertype: string | null
    ) => {
      //  check if the variant already exists and then just add up the quantity
      let isIn = cart.findIndex((c) => c?.product?._id === data?._id);

      if (isIn !== -1) {
        let newCart = [...cart];
        newCart[isIn] = {
          product: data,
          quantity: cart[isIn]?.quantity + quantity,
          type,
          useEvoucher,
          ordertype: ordertype as string,
        };

        //set to cart
        setCart(newCart);
        //save to local storage
        localStorage.setItem("@empomart-cart", JSON.stringify(newCart));
        return true;
      }

      // else just add to cart
      let newCart = [
        ...cart,
        {
          product: data,
          quantity,
          type,
          useEvoucher,
          ordertype: ordertype as string,
        },
      ];
      setCart(newCart);
      localStorage.setItem("@empomart-cart", JSON.stringify(newCart));

      return true;
    },
    removeFromCart: (id: string) => {
      let newCart = cart.filter((c) => c?.product?._id !== id);
      setCart(newCart);
      localStorage.setItem("@empomart-cart", JSON.stringify(newCart));
      return true;
    },
    clearCart: () => {
      setCart([]);
      localStorage.removeItem("@empomart-cart");

      return true;
    },
  };
  return (
    <React.Fragment>
      <CartContext.Provider
        value={{
          cart,
          ...cartContextController,
        }}
      >
        {children}
      </CartContext.Provider>
    </React.Fragment>
  );
};

export default CartContextContainer;
