import * as React from "react";
import { ClassicSpinner } from "react-spinners-kit";
import { useQuery } from "@apollo/client";
import { CURRENT_USER } from "../../services/graphql/Auth";
import { CurrentUserOutputProp, CurrentUserInputProp } from "./types";

export const CurrentUserContext = React.createContext({} as any);

const LoadingComponent = () => {
  return (
    <React.Fragment>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ClassicSpinner color="#fc0f03" size={38} loading />
      </div>
    </React.Fragment>
  );
};

const CurrenUserComponent: React.FC = ({ children }) => {
  const { data, loading } = useQuery<
    CurrentUserOutputProp,
    CurrentUserInputProp
  >(CURRENT_USER, {
    fetchPolicy: "no-cache",
  });

  return (
    <React.Fragment>
      {loading ? (
        <React.Fragment>
          <LoadingComponent />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CurrentUserContext.Provider value={data?.partnerMe}>
            {children}
          </CurrentUserContext.Provider>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export const useCurrentUser = () => React.useContext(CurrentUserContext);

export default CurrenUserComponent;
