import Cookies from "js-cookie";

export const BASE_URL =
	process.env.REACT_APP_SERVER_URL ||
	"https://fibonacci-mlm-engine.herokuapp.com/graphql"
	// "http://localhost:5005/graphql";

class Auth {
	getCipher(): string | null {
		return Cookies.get("fibonnaci-partner-cipher") || null;
	}

	setCipher(token: string): void {
		Cookies.set("fibonnaci-partner-cipher", token);
	}

	clearCipher(): void {
		Cookies.remove("fibonnaci-partner-cipher");
	}
}

export default new Auth();
