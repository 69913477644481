import { gql } from "@apollo/client";

export const partnerMe = gql`
  query {
    partnerMe {
      _id
      code
      lastName
      otherNames
      email
      phone
      emailVerified
      phoneVerified
      placementCode
      suspended
      evoucherBalance
      suspendedAt
      placementDownlines
      autoPlacementDownlines
      suspendedBy {
        _id
        lastName
        otherNames
      }
      suspendedBecause
      partnerCode
      image
      dob
      rank {
        _id
        name
      }
      gender
      activated
      idNumber
      idType
      country {
        name
        _id
        flag
      }
      region {
        _id
        name
        code
      }
      city {
        _id
        name
      }
      residence
      occupation
      payoutDetails {
        payoutMethod
        bankName
        bankBranch
        bankAccountType
        bankAccountName
        bankAccountNumber
        mobileWalletType
        mobileWalletName
        mobileWalletNumber
      }
      entryPackage {
        _id
        name
        icon
        description
        type
        price
        price
        pointValue
        priority
      }
      currentPackage {
        _id
        name
        icon
        description
        type
        price
        price
        pointValue
        priority
      }
      rank {
        _id
        code
        name
        description
        upperBound
        lowerBound
      }
      pointVolumeContainers {
        totalAccumulatedVolume
        performanceTrack {
          personalVolume
          personalAccumulatedVolume
          groupVolume
          groupAccumulatedVolume
        }
        maintenanceTrack {
          personalVolume
          personalAccumulatedVolume
          groupVolume
          groupAccumulatedVolume
        }
        retailTrack {
          personalVolume
          personalAccumulatedVolume
          groupVolume
          groupAccumulatedVolume
        }
        repeatTrack {
          personalVolume
          personalAccumulatedVolume
          groupVolume
          groupAccumulatedVolume
        }
        quarterlyTrack {
          personalVolume
          personalAccumulatedVolume
          groupVolume
          groupAccumulatedVolume
        }
        yearlyTrack {
          personalVolume
          personalAccumulatedVolume
          groupVolume
          groupAccumulatedVolume
        }
        assetTrack {
          group1
          group2
          group3
          group4
        }
        stockistTrack {
          signupVolume
          signupAccumulatedVolume
          orderVolume
          orderAccumulatedVolume
        }
      }
      downlines
      sponsor {
        _id
        lastName
        otherNames
        partnerCode
        gender
      }
      isStockist
      stockistLevel
      stockistOf {
        ... on City {
          name
          _id
        }
        ... on Region {
          _id
          name
        }

        ... on Country {
          flag
          name
          _id
        }
      }
      ecashBalance
      earningsBalance
      insuranceBalance
      yearlyEduShareBalance
      stockistBalance
      orderBalance
      benzBalance
      empoCityBalance
    }
  }
`;
