import { gql } from "@apollo/client";

export const loginUser = gql`
  mutation ($email: String!, $password: String!) {
    loginPartner(input: { username: $email, password: $password }) {
      token
      partner {
        _id
      }
    }
  }
`;

export const updatePassword = gql`
  mutation ($oldPassword: String!, $password: String!) {
    updatePartnerPassword(
      input: { oldPassword: $oldPassword, password: $password }
    ) {
      _id
    }
  }
`;

export const sendParnerCode = gql`
  mutation ($username: String!, $type: CodeType!) {
    sendPartnerCode(input: { username: $username, type: $type }) {
      token
    }
  }
`;

export const verifyPartnerCode = gql`
  mutation ($username: String!, $type: CodeType!, $code: String!) {
    verifyPartnerCode(
      input: { username: $username, type: $type, code: $code }
    ) {
      token
      partner {
        _id
      }
    }
  }
`;

export const resetPartnerPassword = gql`
  mutation ($password: String!) {
    resetPartnerPassword(input: { password: $password }) {
      _id
    }
  }
`;
