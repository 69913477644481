import React, {
  Fragment,
  lazy,
  Suspense,
  useReducer,
  useEffect,
  useMemo,
  createContext,
} from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { TopLoader } from "../../components/Loaders";
import { useIdleTimer } from "react-idle-timer";
import RateContainer from "./rateContext";
import CartContainer from "./cart";
import ClientApollo from "../graphql";
import Auth from "../cookie.config";
import CurrentUser from "./currentUser";

const LoginComponent = lazy(() => import("../../pages/Auth/login"));
const LayoutComponent = lazy(() => import("../../components/layout"));
const ForgotPassword = lazy(() => import("../../pages/Auth/forgotpassword"));
const VerifyCode = lazy(() => import("../../pages/Auth/verify"));
const ResetPassword = lazy(() => import("../../pages/Auth/reset"));

interface ContextState {
  isLoading: boolean;
  isSignout: boolean;
  userToken: object;
}

interface authContextControllerProps {
  signIn: Function;
  signOut: Function;
}

export const AuthContext = createContext(
  [] as (ContextState | authContextControllerProps | any)[]
);

const Manipulator = (prevState: any, action: any) => {
  switch (action.type) {
    case "RESTORE_TOKEN":
      return {
        ...prevState,
        userToken: action.userToken,
        isLoading: false,
      };
    case "SIGN_IN":
      return {
        ...prevState,
        isSignout: false,
        userToken: action.token,
      };
    case "SIGN_OUT":
      return {
        ...prevState,
        isLoading: false,
        userToken: null,
        isSignout: true,
      };
    default:
      return prevState;
  }
};

const SettingsConfig = ({ state }: any) => {
  return (
    <Fragment>
      <BrowserRouter>
        <Suspense fallback={TopLoader()}>
          <ToastProvider autoDismiss>
            <Switch>
              <Route component={LoginComponent} path={"/login"} exact={true} />
              <Route
                component={ForgotPassword}
                path={"/forgotpassword"}
                exact={true}
              />
              <Route component={VerifyCode} path={"/verifycode"} exact={true} />
              <Route
                component={ResetPassword}
                path={"/resetpassword"}
                exact={true}
              />
              <Route component={LayoutComponent} path={"/"} />
            </Switch>
          </ToastProvider>
        </Suspense>
      </BrowserRouter>
    </Fragment>
  );
};

function AppNavigator() {
  const [state, dispatch] = useReducer(Manipulator, {
    isLoading: true,
    isSignout: false,
    userToken: null,
  });

  const handleOnIdle = (event: any) => {
    console.log("last active", getLastActiveTime());
    console.log("time remainng", getRemainingTime());
    let token = Auth.getCipher();
    if (token === null) {
      return;
    }
    Auth.clearCipher();
    window.location.pathname = "/login";
  };

  const { getLastActiveTime, getRemainingTime } = useIdleTimer({
    timeout: 600000,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    let userToken: string | null = Auth.getCipher();
    let data;
    if (userToken) data = JSON.parse(userToken);
    else data = null;
    dispatch({ type: "RESTORE_TOKEN", userToken: data });
  }, []);

  const authContextController = useMemo(
    () => ({
      signIn: async (token: object): Promise<void> => {
        Auth.setCipher(JSON.stringify(token));
        dispatch({ type: "SIGN_IN", token: token });
      },
      signOut: (): void => {
        Auth.clearCipher();
        dispatch({ type: "SIGN_OUT" });
      },
    }),
    []
  );
  return (
    <Fragment>
      {state.isLoading ? (
        <TopLoader />
      ) : (
        <Fragment>
          <AuthContext.Provider value={[authContextController, state]}>
            <ClientApollo>
              <CartContainer>
                <RateContainer>
                  <CurrentUser>
                    <SettingsConfig state={state} />
                  </CurrentUser>
                </RateContainer>
              </CartContainer>
            </ClientApollo>
          </AuthContext.Provider>
        </Fragment>
      )}
    </Fragment>
  );
}

export default AppNavigator;
