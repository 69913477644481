import React, { Fragment } from "react";
import { ClipLoader } from "react-spinners";

const PageLoad = () => {
  return (
    <Fragment>
      <div className={"w-screen h-screen flex justify-center items-center"}>
        <ClipLoader color={"#fc0f03"} loading={true} size={60} />
      </div>
    </Fragment>
  );
};

export default PageLoad;
