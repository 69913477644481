import * as React from "react";

export interface Rate {
  value: string;
  label: string;
  symbol: string;
  rate: number;
  _id: string;
}

interface Props {
  rate: Rate;
  updateRate: (data: Rate) => boolean;
}

const STARTER_RATE: Rate = {
  value: "dollars",
  label: "US Dollars",
  symbol: "$",
  rate: 1,
  _id: "dfkjbdf",
};

export const RateContext = React.createContext({} as Props);

const RateContextContainer: React.FC = ({ children }) => {
  const [rate, setRate] = React.useState<Rate>(STARTER_RATE);

  //check if we have data in local storage
  React.useEffect(() => {
    let rateData = localStorage.getItem("@empomart-rate");
    if (!rateData) setRate(STARTER_RATE);
    else setRate(JSON.parse(rateData));
  }, []);

  const rateContextController = {
    updateRate: (data: Rate) => {
      setRate(data);
      localStorage.setItem("@empomart-rate", JSON.stringify(data));
      return true;
    },
  };

  return (
    <React.Fragment>
      <RateContext.Provider
        value={{
          rate,
          ...rateContextController,
        }}
      >
        {children}
      </RateContext.Provider>
    </React.Fragment>
  );
};

export default RateContextContainer;
